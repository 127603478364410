<template>
  <div class="page-login-desktop">
    <Table
        label="آگهی های نیوفایل"
        icon="mdi-format-list-bulleted"
        :table="table">
      <tr
          v-for="(item,index) in table.items"
          :key="index"
      >
        <td class="text-center">{{ index + 1 }}</td>
        <td class="text-center">
          <ImageViewer :src="item.image"/>
        </td>
        <td class="text-center">{{ item.user }}</td>
        <td class="text-center">{{ item.created_at }}</td>
        <td class="text-center">{{ item.title }}</td>
        <td class="text-center">{{ item.owner }}</td>
        <td class="text-center">{{ item.phone }}</td>
        <td class="text-center">{{ item.address }}</td>
        <td class="text-center">
          <v-chip
              small
              :color="item.status.color"
              text-color="white">
            {{ item.status.name }}
          </v-chip>

          <v-chip
              class="mr-3"
              v-if="item.fileTransaction_type.name"
              small
              :color="item.fileTransaction_type.color"
              text-color="white">
            {{ item.fileTransaction_type.name }}
          </v-chip>
        </td>
        <td class="text-center">{{ item.totalprice }}</td>
        <td class="text-center">{{ item.pricePerMeter }}</td>

        <td class="text-center">


          <v-row no-gutters>
            <v-col :cols="6" class="flex align-center justify-center">
              <ViewButton
                  @click="item.actions.view"
              />
              <EditButton
                  @click="item.actions.edit"
              />
            </v-col>

            <v-col :cols="6" class="flex align-center justify-center">
              <MessageButton
                  @click="item.actions.replay"
              />

              <DeleteButton
                  @click="item.actions.delete"
              />
            </v-col>
          </v-row>
        </td>
      </tr>
    </Table>

    <Pagination
        v-model="filters.skip"
        :key="filters.skip"
        :totalItems="table.totalCount"
        :perPage="filters.take"
        @change="(page)=>{fetchItems({skip: page})}"
    />

    <v-dialog v-model="deleteItem.modal" width="500">
      <v-card>
        <v-card-title class="text-body-1 ">
          <v-icon class="ml-2">
            mdi-information-outline
          </v-icon>
          حذف
        </v-card-title>
        <v-card-text>
          آیا از حذف این فایل مطمئن هستید ؟
        </v-card-text>

        <v-card-actions class="d-flex align-center justify-space-between">
          <v-btn
              @click="deleteItem.modal=false"
              large text color="main">
            لغو
          </v-btn>


          <v-btn
              :loading="deleteItem.loading"
              :disabled="deleteItem.loading"
              @click="destroyItem"
              elevation="0"
              large color="error">
            حذف
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <NewMessage
        @onSubmit="()=>{
          newMessage.modal=false;
        }"
        :messageData="newMessage"
    />

    <FileDetailsModal
        @updateList="()=>{
          file.modal = false;
          fetchItems();
        }"
        v-model="file.modal"
        :file="file"
    />

  </div>
</template>

<script>

import FileDetailsModal from "@/components/FileDetailsModal"
import Table from "@/components/Table"
import ViewButton from "@/components/ViewButton"
import EditButton from "@/components/EditButton"
import MessageButton from "@/components/MessageButton"
import DeleteButton from "@/components/DeleteButton"
import ImageViewer from "@/components/ImageViewer"
import NewMessage from "@/components/NewMessage"
import Pagination from "@/components/Pagination"
import {deleteFile, fetchFiles, updateFileStatus} from "@Newfiling/module-file/src/api";
import {AppConfig, getDataFromUrl, updateUrlQueries} from "@Newfiling/Services";
import {addCommas} from "@persian-tools/persian-tools";

export default {
  name: 'DesktopLogin',

  components: {
    Table,
    Pagination,
    NewMessage,
    ImageViewer,
    ViewButton,
    EditButton,
    MessageButton,
    DeleteButton,
    FileDetailsModal
  },

  data() {
    return {
      file: {
        modal: false,
      },
      filters: {
        take: 10,
        skip: 0,
        type: 3,
      },
      table: {
        headers: [
          '#',
          'عکس',
          'تنظیم کننده',
          'تاریخ ثبت',
          'عنوان',
          'مالک',
          'تلفن',
          'ادرس',
          'وضعیت',
          'قیمت کل / رهن',
          'قیمت متری / اجاره',
          'عملیات',
        ],
        items: [],
        totalCount: 0,
        loading: false,
      },
      deleteItem: {
        modal: false,
        loading: false,
        id: null,
      },
      newMessage: {
        modal: false,
        phoneNumber: null,
      },
      fileTypes: [],
      fileTransactions: [],
      locations: [],
      kitchenTypes: [],
      floorItems: [],
      sleepRoomItems: [],
      telephoneItems: [],
      wcItems: [],
      floorCoverItems: [],
      viewItems: [],
      documentItems: [],
      properties: [],

    }
  },

  beforeMount() {
    this.$store.commit('Breadcrumb/setBreadcrumb', [
      {
        text: 'پنل مدیریت نیوفایل',
        disabled: false,
        href: '/panel/dashboard',
      },
      {
        text: 'لیست آگهی های نیوفایل',
        disabled: true,
        href: '#',
      },
    ])
  },

  mounted() {
    this.$nextTick(async () => {
      const appConfig = (await AppConfig);
      this.fileTypes = appConfig.PropertyType.map(item => {
        return {
          id: item.Id,
          name: item.PropertyType,
        }
      })
      this.fileTransactions = appConfig.Typeoftransaction.map(item => {
        return {
          id: item.Id,
          name: item.Typeoftransaction,
        }
      })
      this.locations = appConfig.neighborhoods.map(item => {
        return {
          id: item.Id,
          name: item.NeighborhoodName,
        }
      })
      this.kitchenTypes = appConfig.kitchen.map(item => {
        return {
          id: item.Id,
          name: item.Servicename,
        }
      })
      this.floorItems = appConfig.Floornumber.map(item => {
        return {
          id: item.Id,
          name: item.FloorName,
        }
      })
      this.sleepRoomItems = appConfig.Numberofsleeps.map(item => {
        return {
          id: item.Id,
          name: item.Numberofsleeps,
        }
      })
      this.telephoneItems = appConfig.Telephonelinenumber.map(item => {
        return {
          id: item.Id,
          name: item.Linestatus,
        }
      })
      this.wcItems = appConfig.Wclist.map(item => {
        return {
          id: item.Id,
          name: item.Servicename,
        }
      })
      this.floorCoverItems = appConfig.FloorCovering.map(item => {
        return {
          id: item.Id,
          name: item.Servicename,
        }
      })
      this.viewItems = appConfig.ViewType.map(item => {
        return {
          id: item.Id,
          name: item.Servicename,
        }
      })
      this.documentItems = appConfig.typeofDocument.map(item => {
        return {
          id: item.Id,
          name: item.Documentname,
        }
      })
      this.properties = appConfig.Features.map(item => {
        return {
          id: item.Id,
          name: item.Persianname,
        }
      })
      this.filters = {
        ...this.filters,
        ...getDataFromUrl(this.$route),
      }
      await this.fetchItems();
    })
  },

  methods: {
    async fetchItems(filters = {}) {
      this.filters = {
        ...this.filters,
        ...filters
      }
      updateUrlQueries(this.$route.path, this.filters);
      this.table.loading = true;
      try {
        const res = (await fetchFiles({
          ...this.filters,
          skip: this.filters.skip * this.filters.take,
        }))?.data || {
          message: [],
          recordcount: 0
        };
        const getFileTitle = (file) => {
          let title = '';
          title = this.fileTransactions.find(i => i.id === file.typeOfTransaction)?.name + " ";
          title = title + this.fileTypes.find(i => i.id === file.propertyType)?.name + " در "
          title = title + this.locations.find(i => i.id === file.locationsid)?.name
          return title;
        }
        const getStatus = (file) => {
          switch (file.filestatus) {
            case 1: {
              return {
                value: file.filestatus,
                name: 'فعال',
                color: 'success'
              }
            }
            case 2: {
              return {
                value: file.filestatus,
                name: 'غیر فعال',
                color: 'error'
              }
            }
            default: {
              return {
                value: file.filestatus,
                name: 'نا معلوم',
                color: '#333'
              }
            }
          }
        }
        const getFileTransactionStatus = (file) => {
          switch (file.fileTransactiontype) {
            case 1: {
              return {
                name: 'اجاره رفته',
                color: 'warning'
              }
            }
            case 2: {
              return {
                name: 'فروش رفته',
                color: 'warning'
              }
            }
            case 3: {
              return {
                name: 'قیمت تغییر کرده',
                color: 'warning'
              }
            }
            case 4: {
              return {
                name: 'منصرف شده',
                color: 'warning'
              }
            }
            default: {
              return {
                name: null,
                color: null,
              }
            }
          }
        }
        const getMobiles = (item) => {
          let phones = '';
          if (item.phone1) {
            phones += item.phone1 + " - "
          }
          if (item.phone2) {
            phones += item.phone2 + " - "
          }
          if (item.phone3) {
            phones += item.phone3 + " - "
          }
          return phones;
        }
        this.table.items = res?.message.map(item => {
          return {
            id: item.id,
            image: item.image1 || '/img/placeholder.png',
            user: item.regulatorname || 'نا معلوم',
            created_at: item.dateSelection,
            title: getFileTitle(item),
            totalprice: addCommas(item.totalprice),
            pricePerMeter: addCommas(item.pricePerMeter),
            status: getStatus(item),
            filestatus: item.filestatus,
            fileTransaction_type: getFileTransactionStatus(item),
            owner: item.ownerName,
            phone: getMobiles(item),
            address: item.propertyAddress,
            actions: {
              edit: () => {
                this.$router.push('/panel/files/edit/' + item.id);
              },

              view: () => {
                this.file = {
                  ...item,
                  id: item.id,
                  modal: true,
                  title: getFileTitle(item),
                  totalprice: addCommas(item.totalprice),
                  pricePerMeter: addCommas(item.pricePerMeter),
                  kitchenType: this.kitchenTypes.find(i => i.id === item.kitchenType)?.name,
                  numberOfSleeps: this.sleepRoomItems.find(i => i.id === item.numberOfSleeps)?.name,
                  floorNumber: this.floorItems.find(i => i.id === item.floorNumber)?.name,
                  telephoneType: this.telephoneItems.find(i => i.id === item.phoneStatus)?.name,
                  wctype: this.wcItems.find(i => i.id === item.wctype)?.name,
                  floorCoveringType: this.floorCoverItems.find(i => i.id === item.floorCoveringType)?.name,
                  viewType: this.viewItems.find(i => i.id === item.viewtype)?.name,
                  typeofDocument: this.documentItems.find(i => i.id === item.typeofDocument)?.name,
                }
              },

              delete: () => {
                this.deleteItem = {
                  ...this.deleteItem,
                  id: item.id,
                  modal: true,
                  loading: false,
                }
              },

              replay: () => {
                this.newMessage = {
                  modal: true,
                  phoneNumber: item.phone1.substring(1),
                }
              },
            }
          }
        });
        this.table.totalCount = res?.recordcount;
      } catch (e) {
        console.log(e)
      }
      this.table.loading = false;
    },

    async destroyItem() {
      this.deleteItem.loading = true;
      try {
        await deleteFile(this.deleteItem.id);
        this.$toast.success('فایل مورد نظر با موفقیت حذف شد.');
        this.deleteItem = {
          ...this.deleteItem,
          modal: false,
          loading: false,
          id: null,
        }
        await this.fetchItems();
      } catch (e) {
        console.log(e)
      }
      this.deleteItem.loading = false;
    },

  }

}
</script>
